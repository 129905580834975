import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { MaintenanceService } from '../../services/maintenance.service';

@Injectable()
export class WhatFixService implements OnDestroy {
  public isInjected: boolean = false;
  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private maintenanceService: MaintenanceService
  ) { }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  public initWhatFix() {

    let s = document.createElement("script");
    s.type = "text/javascript";
    if (this.authService.isProduction()) {
      s.src = "//cdn.whatfix.com/prod/4a9a0870-1514-11eb-b6bc-22a91d6e4464/embed/embed.nocache.js";
    } else {
      s.src = "//whatfix.com/4a9a0870-1514-11eb-b6bc-22a91d6e4464/embed/embed.nocache.js";
    }
    s.async = true;
    document.body.append(s);

    this.isInjected = true;
  }
}
