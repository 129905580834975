import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewUserQueueErrorService } from 'src/app/services/new-user-queue-error.service';
import { EditableLocationUser } from 'src/app/types/editableLocationUser';
import { LocationProfile } from 'src/app/types/locationProfile';
import { LocationUser } from 'src/app/types/locationUser';
import { NewUserQueueEntry } from 'src/app/types/newUserQueueEntry';

@Component({
  selector: 'new-user-queue-location',
  templateUrl: './new-user-queue-location.component.html',
  styleUrls: ['./new-user-queue-location.component.scss']
})
export class NewUserQueueLocationComponent {

  @Input() location: LocationProfile;
  @Input() locationUserListChange: Subject<void>;
  @Input() syncUsers: Subject<LocationUser>;

  disconnect$: Subject<boolean> = new Subject<boolean>();

  deleteUser: Subject<EditableLocationUser> = new Subject<EditableLocationUser>();
  constructor(private queueErrorService: NewUserQueueErrorService) {
    this.deleteUser.asObservable().pipe(takeUntil(this.disconnect$)).subscribe(user => {
      this.deleteUserFromList(user);
      this.queueErrorService.deleteEntry(this.location.countryCode, this.location.slicNumber, user.upsId);
    });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  deleteUserFromList(user: EditableLocationUser) {
    const userIndex = this.location.userList.indexOf(user);
    this.location.userList.splice(userIndex, 1);
    this.locationUserListChange.next();
  }

  formatLocation(location: LocationProfile): string {
    if (!location)
      return '';
      
    let formatted = '';
    const addressEmpty = (location.address?.trim() === '' || location.address?.trim() === ', ,')

    if (location.customName === '' && addressEmpty) formatted = location.slicName;
    if (location.customName === '' && !addressEmpty) formatted = `${location.slicName} - ${location.address}`;
    if (location.customName !== '' && addressEmpty) formatted = `${location.customName} / ${location.slicName}`;
    if (location.customName !== '' && !addressEmpty) formatted = `${location.customName} / ${location.slicName} - ${location.address}`;

    return formatted;
  }

  setError(errorEntry: NewUserQueueEntry) {
    errorEntry.countryCode = this.location.countryCode;
    errorEntry.slicNumber = this.location.slicNumber;

    this.queueErrorService.addEntry(errorEntry);
  }

}
