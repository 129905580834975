import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LocationName } from 'src/app/types/locationName';
import { Role } from 'src/app/types/role';

@Component({
  selector: 'location-address-search-input',
  templateUrl: './location-address-search-input.component.html',
  styleUrls: ['./location-address-search-input.component.scss']
})
export class LocationAddressSearchInputComponent {
  @Input() locationQuery: string | LocationName = {
    slicName: '', customName: '', countryCode: '', slicNumber: '', address: ''
  };

  @Input() excludeViewOnly: boolean = false;
  @Input() adminOnly: boolean = false;
  @Input() clearOnSelect: boolean = false;
  @Input() value: string = '';
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<string | LocationName> = new EventEmitter<LocationName>();

  constructor(private apiService: ApiService, private authService: AuthService) {
  }

  ngOnChanges() {
    this.locationQuery = { slicName: this.value, customName: '', countryCode: '', slicNumber: '', address: '' };
  }

  onQueryChange(val: string) {
    this.onChange.emit(val);
  }

  //Called by ng-typeahead, calls to the API with a search query and shows a dropdown list of possible autocomplete values
  searchLocations = (text$: Observable<string | LocationName>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((term: any) => {
      if ((typeof (term) === 'string' && term.length == 0) || (typeof (term) === 'object' && term.slicName.length === 0)) {
        this.onSelect.emit(null);
      }

      if (typeof (term) === 'object') {
        return this.apiService.getLocationNamesWithAddress(term.slicName).pipe(map(this.filterResults.bind(this)))
      } else if (term.length > 1) {
        return this.apiService.getLocationNamesWithAddress(term).pipe(map(this.filterResults.bind(this)))
      } else {
        return []
      }

    })
  );

  //Occurs when the user clicks on an autocompleted item. Will cause locations to load.
  onTypeaheadSelect(item: string | LocationName) {
    if (typeof (item) !== 'object') item = { slicName: item, customName: '', countryCode: '', slicNumber: '' };
    this.onSelect.emit(item);
  }

  locationInputFormatter(result: LocationName) {
    const addressEmpty = (result.address?.trim() === ', ,' || result.address?.trim() === '');

    if (!result || !result.slicName || this.clearOnSelect) return '';
    if (result.customName === '' && addressEmpty) return result.slicName;
    if (result.customName === '' && !addressEmpty) return `${result.slicName} - ${result.address}`;
    if (result.customName !== '' && addressEmpty) return `${result.slicName} (${result.customName})`;
    return `${result.slicName} (${result.customName}) - ${result.address}`;
  }

  locationSearchFormatter(result: LocationName) {
    const addressEmpty = (result.address?.trim() === ', ,' || result.address?.trim() === '');

    if (!result || !result.slicName) return '';
    if (result.customName === '' && addressEmpty) return result.slicName;
    if (result.customName === '' && !addressEmpty) return `${result.slicName} - ${result.address}`;
    if (result.customName !== '' && addressEmpty) return `${result.slicName} (${result.customName})`;
    return `${result.slicName} (${result.customName}) - ${result.address}`;
  }

  private filterResults(results: LocationName[]) {
    return results
      .filter(loc =>
        (this.adminOnly) ?
          this.authService.hasLocationRole(loc.slicName, Role.Admin) :
          (this.authService.hasLocationRole(loc.slicName, Role.User) ||
            !this.excludeViewOnly)
      );
  }

}
