import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { StringService } from 'src/app/services/string.service';
import { EditableLocationUser } from 'src/app/types/editableLocationUser';
import { LangSection, LangText } from 'src/app/types/language';
import { LocationUser } from 'src/app/types/locationUser';
import { NewUserQueueEntry } from 'src/app/types/newUserQueueEntry';
import { Role } from 'src/app/types/role';

@Component({
  selector: 'queue-user',
  templateUrl: './queue-user.component.html',
  styleUrls: ['./queue-user.component.scss']
})
export class QueueUserComponent extends LocalizableComponent implements OnInit, OnDestroy, OnChanges {

  @Input() user: EditableLocationUser;
  @Input() deleteUser: Subject<LocationUser>;
  @Input() syncUsers: Subject<LocationUser>;
  @Input() locationUserListChange: Subject<void>;
  @Output() onError: EventEmitter<NewUserQueueEntry> = new EventEmitter<NewUserQueueEntry>();
  emailRegex = /^[a-zA-Z0-9_\-'!#$%&*+/=?^`{|}~\.]+@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,5}$/;
  emailIsValid = true;
  roleIsValid = true;
  enableConfirm = false;
  $disconnect: Subject<boolean> = new Subject<boolean>();

  emailEdit: string;
  roleEdit: Role;

  roles = [
    {display: this.localize(LangSection.Role, LangText.Administrator), value: Role.Admin},
    {display: this.localize(LangSection.Role, LangText.ViewEdit), value: Role.User},
    {display: this.localize(LangSection.Role, LangText.ViewOnly), value: Role.ReadonlyUser}
  ];
  constructor(private stringService: StringService) { 
    super();
  }

  ngOnInit(): void {
    this.emailEdit = this.user?.email;
    this.roleEdit = this.user?.role;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      this.validateEmail(this.user?.email);
      this.validateRole(this.user?.role)

      this.emitError();
    }
  }

  ngOnDestroy(): void {
    this.$disconnect.next(true);
    this.$disconnect.unsubscribe();
  }

  validateEmail(email: string) {
    if(this.emailRegex.test(this.stringService.normalizeString(email))) {
      this.emailIsValid = true;
    }
    else {
      this.emailIsValid = false;
    }

    this.enableConfirmation();
  }

  validateRole(role: Role) {
    this.roleIsValid = role != Role.NonUser;

    this.enableConfirmation();
  }

  enableConfirmation() {

    this.enableConfirm = (this.emailEdit != this.user?.email || this.roleEdit != this.user?.role) && this.emailIsValid && this.roleIsValid;
  }

  roleDisplay(role: Role) {
    switch (+role) {
      case Role.ReadonlyUser: return this.localize(this.langSection.Role, this.langText.ViewOnly);
      case Role.User: return this.localize(this.langSection.Role, this.langText.ViewEdit);
      case Role.Admin: return this.localize(this.langSection.Role, this.langText.Administrator);
    }
    return '';
  }

  edit() {
    this.emailEdit = this.user?.email;
    this.roleEdit = this.user?.role;
    this.user.isEditing = true;
    this.enableConfirmation();
    this.locationUserListChange.next();
  }

  delete() {
    this.deleteUser.next(this.user);
  }

  updateUser() {
    this.user.email = this.emailEdit;
    this.user.role = this.roleEdit;
    this.user.isEditing = false;
    this.syncUsers.next(this.user);
    this.locationUserListChange.next();
    this.validateEmail(this.user?.email);
    this.validateRole(this.user?.role);
    this.emitError();
  }

  cancel(event: Event) {
    event.preventDefault();
    this.emailEdit = this.user?.email;
    this.roleEdit = this.user?.role;
    this.user.isEditing = false;
    this.validateEmail(this.emailEdit);
    this.validateRole(this.roleEdit);
    this.locationUserListChange.next();
  }

  emitError() {
    const hasError = (!this.emailIsValid || !this.roleIsValid)
    this.onError.emit({ countryCode: '', slicNumber: '', upsId: this.user?.upsId, hasError: hasError });
  }
}
