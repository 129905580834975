import { Component } from '@angular/core';
import { LocalizableComponent } from '../components/localizable/localizable.component';

@Component({
  selector: 'ups-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends LocalizableComponent {
  year: number = new Date().getUTCFullYear();
  showLegalMenu: boolean = false;

  constructor() {
    super();
  }

  toggleLegalMenuClick() {
    this.showLegalMenu = !this.showLegalMenu;
  }

}
