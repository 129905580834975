import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { LocationInfo } from 'src/app/types/locationInfo';
import { LocationProfile } from 'src/app/types/locationProfile';
import { ApiService } from '../../api.service';
import { AuthService } from '../../auth/auth.service';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { LocationName } from '../../types/locationName';
import { Role } from '../../types/role';
import { SetupIncompleteModalComponent } from '../setup-incomplete-modal/setup-incomplete-modal.component';

@Component({
  selector: 'add-request',
  templateUrl: './add-request.component.html',
  styleUrls: ['./add-request.component.scss']
})
export class AddRequestComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() slic: LocationName | string | null = null;
  formattedValue: string = '';
  type: string = "Switch";
  showError: boolean = false;
  disconnect$: Subject<boolean> = new Subject<boolean>();

  requestTypes: { name: string, value: string }[] = [
    { name: this.localize(this.langSection.RequestType, this.langText.Switch), value: 'Switch' },
    { name: this.localize(this.langSection.RequestType, this.langText.LiveLoad), value: 'Live Load' },
    { name: this.localize(this.langSection.RequestType, this.langText.RequestEmpty), value: 'Request Empty' },
    { name: this.localize(this.langSection.RequestType, this.langText.RetrieveLoad), value: 'Retrieve Load' },
    { name: this.localize(this.langSection.RequestType, this.langText.RetrieveEmpty), value: 'Retrieve Empty' },
    { name: this.localize(this.langSection.RequestType, this.langText.REALIGN), value: 'Realign' }
  ];

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    modalDialogClass: 'modal-setup-incomplete'
  };

  locationProfile: LocationProfile = null;
  locations: LocationInfo[] = [];

  constructor(
    private store: Store<AppState>,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    if (!!this.slic) {
      this.formattedValue = this.slic.toString();
    }
    this.store.select(s => s.locationList).pipe(takeUntil(this.disconnect$)).subscribe(state => {
      if (state.locations)
        this.locations = state.locations;
    })
  }

  ngAfterViewInit() {
    window.name = 'AddRequest';
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  onLocationSelect(slic: string | LocationName) {
    this.showError = false;
    if (slic == null) {
      this.formattedValue = '';
      return;
    }

    if (typeof (slic) === 'string') {
      this.formattedValue = slic;
    }
    else {
      const slicAsLocName = slic as LocationName;
      const addressEmpty = (slicAsLocName.address?.trim() === '' || slicAsLocName.address?.trim() === ', ,')
      if (slicAsLocName.customName === '' && addressEmpty) this.formattedValue = slicAsLocName.slicName;
      if (slicAsLocName.customName === '' && !addressEmpty) this.formattedValue = `${slicAsLocName.slicName} - ${slicAsLocName.address}`;
      if (slicAsLocName.customName !== '' && addressEmpty) this.formattedValue = `${slicAsLocName.slicName} (${slicAsLocName.customName})`;
      if (slicAsLocName.customName !== '' && !addressEmpty) this.formattedValue = `${slicAsLocName.slicName} (${slicAsLocName.customName}) - ${slicAsLocName.address}`
    }
  }

  onLocationValueChange(query: string) {
    this.slic = query;
  }

  onRequestTypeSelected(type: string) {
    this.type = type;
  }

  onNext() {
    let slic = '';
    if (this.slic === null && !this.slic) {
      this.showError = true;
    }
    else if ((typeof (this.slic) === 'string' && this.slic !== "") || (typeof (this.slic) === 'object')) {
      if (typeof (this.slic) === 'object') {
        slic = this.slic.slicName;
      }
      else {
        slic = this.slic;
      }

      this.locationProfile = this.locations?.find(loc => slic == loc.slicName)?.profile;
      if (this.locationProfile && this.authService.hasLocationRole(this.locationProfile?.slicName, Role.User)) {
        if (this.locationProfile.defaultResponsibleSite && this.locationProfile.numberOfDestinations > 0) {
          this.router.navigateByUrl(`/add-request/${this.locationProfile.slicName}/${this.getSlug()}`);
        }
        else {
          this.showModal();
        }
      }
      else {
        this.showError = true;
      }
    } else {
      this.showError = true;
    }
  }

  private getSlug() {
    return this.type.split(' ').join('-').toLowerCase();
  }

  showModal() {
    let slicName = "";
    if (typeof (this.slic) === 'string' && this.slic !== "") {
      slicName = (<string>this.slic).toUpperCase();
    }
    else if (typeof (this.slic) === 'object') {
      slicName = this.slic.slicName;
    }
    const modalRef = this.modalService.open(SetupIncompleteModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.slicName = slicName;
    
    return modalRef;
  }
}

export interface NewRequestInfo {
  slic: string;
  requestType: string;
}

